import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import { DropdownMenu, DropdownItem } from 'vant';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Load from '../lyout/loading.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Load
  },
  data() {
    return {
      search_value: '',
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      banner: [],
      goods: [],
      news: [],
      user_name: "",
      pass: "",
      r_user_name: "",
      r_pass: "",
      r_zfpass: "",
      code: "",
      active: 0,
      message: "",
      load: true
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : "0000";
    setTimeout(() => {
      this.load = false;
    }, 0);
  },
  methods: {
    onClickLeft() {
      api.chat(0);
    },
    //切换语言
    select(e) {
      local.saveInfo('setting_lang', e);
      window.location.reload();
    },
    get_imdex_data: function () {
      api.all('/api/user/init', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
        } else {
          //console.log(data);
        }
      });
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
    },
    login: function () {
      let _this = this;
      api.all('/api/user/index', {
        user_name: this.user_name,
        pass: this.pass
      }, (err, data) => {
        showToast(data.msg);
        if (!err && data.code === 1) {
          //console.log('login_ok');
          api.setAuthorization(data.data.token.access_token);
          _this.$router.push({
            name: 'index',
            query: {
              id: 0
            }
          });
        }
      });
    },
    zhu: function () {
      let _this = this;
      api.all('/api/user/zhu', {
        message: this.message
      }, (err, data) => {
        showToast(data.msg);
        if (!err && data.code === 1) {
          //console.log('login_ok');
          api.setAuthorization(data.data.token.access_token);
          _this.$router.push({
            name: 'index',
            query: {
              id: 0
            }
          });
        }
      });
    },
    to: function (id1) {
      let _this = this;
      _this.$router.push({
        name: id1,
        query: {
          id: 0
        }
      });
    },
    chat: function (type = 0) {
      api.chat(type);
    }
  }
};